import './App.css';
import DialogProvider from './components/dialogs/DialogProvider';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './components/login.component/login';
import Dashboard from './modules/dashboard/dashboard';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector } from './store';
import { useEffect, useState } from 'react';
import { CLEAR_CLIENT_SCOPES, SESSION_KEY, SESSION_TYPE } from './config/default.config';
import { postCrossDomainMessage } from './store/keycloak/token-services';
import { sessionStorageUtils } from './utils/session-storage.utils';

function App() {
  const loading = useAppSelector((state) => state.seqrOps.loadingState.loading);
  const navigate = useNavigate();
  const [ready, setReady] = useState<boolean>(false); 

  useEffect(()=>{
    let appsuiteTokenData :any = sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY);
    let tokenData :any = localStorage.getItem(SESSION_KEY.LOCAL_STORAGE_KEY);
    if(tokenData !== null || appsuiteTokenData != null ){
      setReady(true);
      navigate('/dashboard');
    }else{
      setReady(true);
      navigate('/');
    }

    if(localStorage.getItem(SESSION_KEY.CLEAR_ALL_TOKEN_DATA_KEY) !== null){
      postCrossDomainMessage('cl3er', SESSION_TYPE.USER_LOGOUT_TYPE, CLEAR_CLIENT_SCOPES );
      localStorage.clear();
    }
  },[])

  if(!ready){
    return null;
  }
  
  return (
    <div className="App">
    <DialogProvider>
    <Routes>
      <Route path="/" element={<Login/> }></Route>
      <Route path="/dashboard" element={<Dashboard/> }></Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
    </DialogProvider>
    <ToastContainer
        position="bottom-right"
        autoClose={4000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
       {loading ? 
      <div className="loader-row">
        <div className="loader">
        <div id="ld4">
          <div>
          </div>
          <div>
          </div>
          <div>
          </div>
          <div>
          </div>
          </div>
          </div>
          </div> : null}
    </div>
  );
}

export default App;
