import seqropsLogo from "../../assets/images/equserv_conworx_logo.svg";
import seqropsMenuBtn from "../../assets/images/seqrops_menu_icon.svg";
import seqropsLogoutBtn from "../../assets/images/seqrops-exit-btn.svg";
import seqropsSettingsIcon from "../../assets/images/seqrops-settings_icon.svg";
import seqropsDeviceManagementIcon from "../../assets/images/device_management_icon.svg";
import seqropsModelsIcon from "../../assets/images/asset_model_icon.svg";
import seqropsAssetsIcon from "../../assets/images/asset_management_icon.svg";
import managementDashboardIcon from "../../assets/images/management_dashboard_icon.svg";
import operationalDashboardIcon from "../../assets/images/operational_dashboard.svg";
import seqropsFsmIcon from "../../assets/images/fsm_icon.svg";
import "./navbar.scss";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  addAuthorizations,
  setTokens,
} from "../../store/keycloak/asset-keycloak.slice";
import { useDispatch } from "react-redux";
import { assetKeycloakActions } from "../../store/keycloak/asset-keycloak.action";
import {
  ILogoutTokenPayload,
  ITokenDetails,
  IUserAuthorizations,
  IUserTokenDetails,
} from "../../store/keycloak/asset-keycloak.interface";
import { useAppSelector } from "../../store";
import { setLogin } from "../../store/seqr-ops/seqr-ops.slice";
import {
  ASSET_URLS,
  CLEAR_CLIENT_SCOPES,
  IFRAME_ID,
  KEYCLOAK_CLIENTS,
  SESSION_KEY,
  SESSION_TYPE,
} from "../../config/default.config";
import { Sidebar } from "primereact/sidebar";
import { useEffect, useRef, useState } from "react";
import { postCrossDomainMessage } from "../../store/keycloak/token-services";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { OverlayPanel } from "primereact/overlaypanel";
import { encryptUtils } from "../../utils/encryption.utils";

function Navbar(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const op = useRef<OverlayPanel>(null);
  const authorozedScopes = useAppSelector((state) => state.assetKeycloak);
  const [showDialog, setShowDialog] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();

  const setSuccess = (state: boolean) => {
    const ITokenDetails: ITokenDetails = {
      auth_token: "",
      refresh_token: "",
      client_id: "",
      client_secret: "",
      username: "",
      password: "",
    };

    dispatch(setTokens(ITokenDetails));
    dispatch(setLogin(false));
    dispatch(addAuthorizations(CLEAR_CLIENT_SCOPES));
    postCrossDomainMessage(
      "cl3er",
      SESSION_TYPE.USER_LOGOUT_TYPE,
      CLEAR_CLIENT_SCOPES
    );
    localStorage.clear();
    navigate("/");
    if (state) {
    }
  };
  const handleLogOut = async () => {
    let ILogoutTokenPayload: ILogoutTokenPayload = {
      refresh_token: authorozedScopes.refresh_token,
      client_id: authorozedScopes.client_id,
      client_secret: authorozedScopes.client_secret,
    };
    setVisibleRight(false);
    await assetKeycloakActions.logoutToken(ILogoutTokenPayload, setSuccess);
  };

  const hideDialog = () => {
    setShowDialog(false);
  };

  const confirmLogout = () => {
    setShowDialog(true);
  };

  const footer = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleLogOut}
        autoFocus
      />
    </div>
  );

  const handleDashboardClick = (domain: string) => {

    let postMsg = {
        type: SESSION_TYPE.DOMAIN_PASS_TYPE,
        domain: encryptUtils.encryptURL(domain)
    };

    const iframe = document.getElementById(
        IFRAME_ID.DASHBOARD_IFRAME_ID
    ) as HTMLIFrameElement;
    iframe.contentWindow?.postMessage(postMsg, `${process.env.REACT_APP_DASHBOARD_URL}`);
}

  useEffect(() => {
    if (
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !==
      null
    ) {
      let userTokenDetails: IUserTokenDetails =
        sessionStorageUtils.getLocalStorage(
          SESSION_KEY.LOCAL_STORAGE_KEY
        )?.token;
      if (userTokenDetails !== null) {
        setUserScopesFromLocalStorage(userTokenDetails?.userAuthorizations);
      }
    }
  }, []);

  return (
    <div>
      {" "}
      <div className="seqrops-navbar-wrapper"  onMouseLeave={(e)=>{
        op.current && op.current?.hide()
      }}>
        <div className="container-fluid">
          <div className="row">
            <div className="d-flex justify-content-between">
              <div className="seqrops-logo-section">
                <img src={seqropsLogo} alt="seqrops logo" />
                <span>Home</span>
              </div>
              <div className="seqrops-user-section">
                {/* <Tooltip title="settings">
                  <button className="seqrops-settings">
                    <img
                      src={seqropsSettingsIcon}
                      alt="seqrops settings logo"
                    />
                  </button>
                </Tooltip> */}
                <Tooltip title="menu">
                  <button className="seqrops-settings">
                    <img
                      src={seqropsMenuBtn}
                      alt="seqrops settings logo"
                      onClick={(e) => op.current && op.current?.toggle(e)}
                    />
                  </button>
                </Tooltip>
              </div>
              <OverlayPanel ref={op}>
                <div className="d-flex align-items-center header-sidebar">
                  <div className="me-auto">
                    <h4>{props.loggeduser}</h4>
                  </div>
                  <div className="logout-section">
                    <span>Logout</span>
                    <img
                      src={seqropsLogoutBtn}
                      alt="Log out"
                      className="img-fluid asset-logout"
                      onClick={() => {
                        confirmLogout();
                      }}
                    />
                  </div>
                </div>
                <div className="seqrops-asset-apps">
                  <div className="row seqrops-asset-apps-row">
                    {userScopesFromLocalStorage !== undefined &&
                      userScopesFromLocalStorage.asset_models && (
                        <>
                          <div className="col target-app-container">
                            <a href={ASSET_URLS.ASSET_MODELS_URL}>
                              <div className="div">
                                <img
                                  src={seqropsModelsIcon}
                                  alt="models"
                                  className="img-fluid asset-images"
                                />
                                <span>Asset Models</span>
                              </div>
                            </a>
                          </div>
                        </>
                      )}
                    {userScopesFromLocalStorage !== undefined &&
                      userScopesFromLocalStorage.asset_management && (
                        <>
                          <div className="col target-app-container">
                            <a href={ASSET_URLS.ASSET_MANAGEMENT_URL}>
                              <div className="div">
                                <img
                                  src={seqropsAssetsIcon}
                                  alt="management"
                                  className="img-fluid asset-images"
                                />
                                <span>Asset Management</span>
                              </div>
                            </a>
                          </div>
                        </>
                      )}
                    {userScopesFromLocalStorage !== undefined &&
                      userScopesFromLocalStorage.device_management && (
                        <>
                          <div className="col target-app-container">
                            <a href={ASSET_URLS.DEVICE_MANAGEMENT_URL}>
                              <div className="div">
                                <img
                                  src={seqropsDeviceManagementIcon}
                                  alt="device"
                                  className="img-fluid asset-images"
                                />
                                <span>Device Management</span>
                              </div>
                            </a>
                          </div>
                        </>
                      )}
                  </div>
                  <div className="row">
                    {userScopesFromLocalStorage !== undefined &&
                      userScopesFromLocalStorage.field_service_management && (
                        <>
                          <div className="col target-app-container">
                            <a href={ASSET_URLS.FIELD_SERVICE_MANAGEMENT_URL}>
                              <div className="div">
                                <img
                                  src={seqropsFsmIcon}
                                  alt="fsm"
                                  className="img-fluid asset-images"
                                />
                                <span>Field Service Management</span>
                              </div>
                            </a>
                          </div>
                        </>
                      )}
                    {userScopesFromLocalStorage !== undefined &&
                      userScopesFromLocalStorage.operational_dashboard && (
                        <>
                          <div className="col target-app-container" onClick={() => { handleDashboardClick(KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD) }}>
                            <a href={ASSET_URLS.OPERATIONAL_DASHBOARD_URL+"/?dashboardType=operational-dashboard"}>
                              <div className="div"> 
                                <img
                                  src={operationalDashboardIcon}
                                  alt="operational"
                                  className="img-fluid asset-images"
                                />
                                <span>Operations Dashboard</span>
                              </div>
                            </a>
                          </div>
                        </>
                      )}
                    {userScopesFromLocalStorage !== undefined &&
                      userScopesFromLocalStorage.management_dashboard && (
                        <>
                          <div className="col target-app-container"  onClick={() => { handleDashboardClick(KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD) }}>
                            <a href={ASSET_URLS.MANAGEMENT_DASHBOARD_URL+"/?dashboardType=management-dashboard"}>
                              <div className="div">
                                <img
                                  src={managementDashboardIcon}
                                  alt="management"
                                  className="img-fluid asset-images"
                                />
                                <span>Management Dashboard</span>
                              </div>
                            </a>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </OverlayPanel>
            </div>
          </div>
        </div>
        <Dialog
          visible={showDialog}
          onHide={hideDialog}
          header="Confirm Logout"
          footer={footer}
          modal
          style={{ width: "350px" }}
        >
          <div>Are you sure you want to logout?</div>
        </Dialog>
      </div>
    </div>
  );
}

export default Navbar;
