import React, { useEffect, useState } from 'react'
import './dashboard.scss';
import { useAppDispatch, useAppSelector } from '../../store';
import { ASSET_REDIRECT_SCOPES, ASSET_SUITE_LOGIN_URL, ASSET_URLS, IFRAME_ID, KEYCLOAK_CLIENTS, SESSION_KEY, SESSION_TYPE } from '../../config/default.config';
import assetModelsIcon from "../../assets/images/asset_model_icon.svg";
import assetManagementIcon from "../../assets/images/asset_management_icon.svg";
import adminConsoleIcon from "../../assets/images/device_management_icon.svg";
import managementDashboardIcon from "../../assets/images/management_dashboard_icon.svg";
import operationalDashboardIcon from "../../assets/images/operational_dashboard.svg";
import fsmIcon from "../../assets/images/fsm_icon.svg";
import { IUserAuthorizations, IUserTokenDetails } from '../../store/keycloak/asset-keycloak.interface';
import Navbar from '../../components/navbar.component/navbar';
import { setTokens } from '../../store/keycloak/asset-keycloak.slice';
import { sessionStorageUtils } from '../../utils/session-storage.utils';
import { encryptUtils } from '../../utils/encryption.utils';

function Dashboard() {

    const authorozedScopes = useAppSelector((state) => state.assetKeycloak);
    const dispatch = useAppDispatch();
    const [loggedUser, setLoggedUser] = useState<string>();
    const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] = useState<IUserAuthorizations>();
    const [ready, setReady] = useState<boolean>(false);
    const [zoomedTile, setZoomedTile] = useState<string>('');
    const useParams  = new URLSearchParams(window.location.search);

    const handleMouseEnter = (tile: string) => {
        setZoomedTile(tile);
    };

    const handleMouseLeave = () => {
        setZoomedTile('');
    };

    useEffect(() => {

        if (sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !== null) {
            let userTokenDetails: IUserTokenDetails = sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY)?.token;
            // let originDomain : any = localStorage.getItem(SESSION_KEY.DOMAIN_KEY)

            // //direct access users
            // if(originDomain === null && DIRECT_DASHBOARD_ACCESS_USERS.includes(userTokenDetails?.username)){
            //     handleDashboardClick(KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD);
            //     window.location.href = ASSET_URLS.OPERATIONAL_DASHBOARD_URL;
            //     return;
            // }

            if (userTokenDetails !== null) {
                console.log("userTokenDetailsuserTokenDetails", userTokenDetails);
                setUserScopesFromLocalStorage(userTokenDetails?.userAuthorizations);
                setLoggedUser(userTokenDetails?.username);
                dispatch(setTokens(userTokenDetails)) 

                const newArray = [];
                let data: any = userTokenDetails?.userAuthorizations;
                const trueKeys = Object.keys(data).filter(key => data[key]);
                trueKeys.forEach(key => {
                    newArray.push(key);
                });
                if (trueKeys.length === 1) {
                    let target = trueKeys[0];
                    switch (target) {
                        case ASSET_REDIRECT_SCOPES.ASSET_MODELS:
                            window.location.href = ASSET_URLS.ASSET_MODELS_URL
                            break;
                        case ASSET_REDIRECT_SCOPES.ASSET_MANAGEMENT:
                            window.location.href = ASSET_URLS.ASSET_MANAGEMENT_URL
                            break;
                        case ASSET_REDIRECT_SCOPES.DEVICE_MANAGEMENT:
                            window.location.href = ASSET_URLS.DEVICE_MANAGEMENT_URL
                            break;
                        case ASSET_REDIRECT_SCOPES.FIELD_SERVICE_MANAGEMENT:
                            window.location.href = ASSET_URLS.FIELD_SERVICE_MANAGEMENT_URL
                            break;
                        case ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD:
                            window.location.href = ASSET_URLS.OPERATIONAL_DASHBOARD_URL
                            break;
                        case ASSET_REDIRECT_SCOPES.MANAGEMENT_DASHBOARD:
                            window.location.href = ASSET_URLS.MANAGEMENT_DASHBOARD_URL
                            break;
                        default:
                            window.location.href = ASSET_URLS.APP_SUITE_URL
                    }
                }
                 else {
                    setReady(true);
                }
            }
        } else {
            window.location.href = ASSET_SUITE_LOGIN_URL
        }

    }, [])

    const renderAssetModels = () => {

        return (
            <div
                className={`seqrops-dashboard-tile-wrapper ${zoomedTile === 'assetModels' ? 'zoom-effect' : ''}`}
                onMouseEnter={() => handleMouseEnter('assetModels')}
                onMouseLeave={handleMouseLeave}
            >
                <a href={ASSET_URLS.ASSET_MODELS_URL}>
                    <div className="seqrops-tile-inner-section">
                        <img src={assetModelsIcon} className="img-fluid" alt="asset-models-img" />
                        <span>Asset Models</span>
                    </div>
                </a>
            </div>
        )
    }

    const renderAssetManagement = () => {
        return (
            <div
                className={`seqrops-dashboard-tile-wrapper ${zoomedTile === 'assetManagement' ? 'zoom-effect' : ''}`}
                onMouseEnter={() => handleMouseEnter('assetManagement')}
                onMouseLeave={handleMouseLeave}
            >
                <a href={ASSET_URLS.ASSET_MANAGEMENT_URL}>
                    <div className="seqrops-tile-inner-section">
                        <img src={assetManagementIcon} className="img-fluid" alt="asset-management-img" />
                        <span>Asset Management</span>
                    </div>
                </a>
            </div>
        )
    }

    const renderDeviceManagement = () => {
        return (
            <div
                className={`seqrops-dashboard-tile-wrapper ${zoomedTile === 'deviceManagement' ? 'zoom-effect' : ''}`}
                onMouseEnter={() => handleMouseEnter('deviceManagement')}
                onMouseLeave={handleMouseLeave}
            >
                <a href={ASSET_URLS.DEVICE_MANAGEMENT_URL}>
                    <div className="seqrops-tile-inner-section">
                        <img src={adminConsoleIcon} className="img-fluid" alt="admin-console-img" />
                        <span>Device Management</span>
                    </div>
                </a>
            </div>
        )
    }

    const renderFieldServiceManagement = () => {
        return (
            <div
                className={`seqrops-dashboard-tile-wrapper ${zoomedTile === 'fieldServiceManagement' ? 'zoom-effect' : ''}`}
                onMouseEnter={() => handleMouseEnter('fieldServiceManagement')}
                onMouseLeave={handleMouseLeave}
            >
                <a href={ASSET_URLS.FIELD_SERVICE_MANAGEMENT_URL}>
                    <div className="seqrops-tile-inner-section">
                        <img src={fsmIcon} className="img-fluid" alt="FSM-img" />
                        <span>Field Service Management</span>
                    </div>
                </a>
            </div>
        )
    }

    const renderManagementDashboard = () => {
        return (
            <div
                className={`seqrops-dashboard-tile-wrapper ${zoomedTile === 'managementDashboard' ? 'zoom-effect' : ''}`}
                onMouseEnter={() => handleMouseEnter('managementDashboard')}
                onMouseLeave={handleMouseLeave}
                onClick={() => { handleDashboardClick(KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD) }}
            >
                <a href={ASSET_URLS.MANAGEMENT_DASHBOARD_URL+"/?dashboardType=management-dashboard"}>
                    <div className="seqrops-tile-inner-section">
                        <img src={managementDashboardIcon} className="img-fluid" alt="efficency-dsh-img" />
                        <span>Management Dashboard</span>
                    </div>
                </a>
            </div>
        )
    }

    const renderOperationalDashboard = () => {

        return (
            <div
                className={`seqrops-dashboard-tile-wrapper ${zoomedTile === 'operationalDashboard' ? 'zoom-effect' : ''}`}
                onMouseEnter={() => handleMouseEnter('operationalDashboard')}
                onMouseLeave={handleMouseLeave}
                onClick={() => { handleDashboardClick(KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD) }}
            >
                <a href={ASSET_URLS.OPERATIONAL_DASHBOARD_URL+"/?dashboardType=operational-dashboard"}>
                    <div className="seqrops-tile-inner-section">
                        <img src={operationalDashboardIcon} className="img-fluid" alt="operation-dsh-img" />
                        <span>Operations Dashboard</span>
                    </div>
                </a>
            </div>
        )
    }

    const handleDashboardClick = (domain: string) => {

        let postMsg = {
            type: SESSION_TYPE.DOMAIN_PASS_TYPE,
            domain: encryptUtils.encryptURL(domain)
        };

        const iframe = document.getElementById(
            IFRAME_ID.DASHBOARD_IFRAME_ID
        ) as HTMLIFrameElement;
        iframe.contentWindow?.postMessage(postMsg, `${process.env.REACT_APP_DASHBOARD_URL}`);
    }


    if (!ready) {
        return null;
    }

    return (
        <>
            {ready &&
                <>
                    <div className="seqrops-dashboard-wrapper">
                        <div className="seqrops-navbar-section">
                            <Navbar loggeduser={userScopesFromLocalStorage !== undefined ? loggedUser : authorozedScopes.username} />
                        </div>
                        <div className="container mt-4">
                            <div className="tile-wrapper-main flex-wrap">
                                <React.Fragment>
                                    {(userScopesFromLocalStorage !== undefined ? userScopesFromLocalStorage.asset_models : authorozedScopes.userAuthorizations.asset_models) && renderAssetModels()}
                                    {(userScopesFromLocalStorage !== undefined ? userScopesFromLocalStorage.asset_management : authorozedScopes.userAuthorizations.asset_management) && renderAssetManagement()}
                                    {(userScopesFromLocalStorage !== undefined ? userScopesFromLocalStorage.device_management : authorozedScopes.userAuthorizations.device_management) && renderDeviceManagement()}
                                    {(userScopesFromLocalStorage !== undefined ? userScopesFromLocalStorage.field_service_management : authorozedScopes.userAuthorizations.field_service_management) && renderFieldServiceManagement()}
                                    {(userScopesFromLocalStorage !== undefined ? userScopesFromLocalStorage.operational_dashboard : authorozedScopes.userAuthorizations.operational_dashboard) && renderOperationalDashboard()}
                                    {(userScopesFromLocalStorage !== undefined ? userScopesFromLocalStorage.management_dashboard : authorozedScopes.userAuthorizations.management_dashboard) && renderManagementDashboard()}
                                </React.Fragment>

                                {/* //ux user case next if */}
                                {/* {authorozedScopes.username === "ux user" && 
                    <>
                        {renderAssetManagement() }    
                        {renderAssetModels() }    
                        {renderDeviceManagement() }   
                        {renderFieldServiceManagement() }
                        {renderManagementDashboard() }
                        {renderOperationalDashboard() }
                    </>} */}
                            </div>
                        </div>
                    </div>
                </>}
        </>
    )
}

export default Dashboard
