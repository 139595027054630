import { AppThunk } from "../index";
import { setLoading } from "./seqr-ops.slice";

const setLoader = (loading: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoading(loading));
  };
};
export const seqrOpsActions = {
  setLoader,
};
