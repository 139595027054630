import { FieldErrors, useForm } from "react-hook-form";
import {
  ILoginCredentials,
  ITokenDetails,
} from "../../../store/keycloak/asset-keycloak.interface";
import { assetKeycloakActions } from "../../../store/keycloak/asset-keycloak.action";
import {
  KEYCLOAK_CLIENTS,
  KEYCLOAK_CLIENT_SECRETS,
  KEYCLOAK_GRANT_TYPES,
  MESSAGES,
} from "../../../config/default.config";
import { useDispatch } from "react-redux";
import "./login-form.scss";
import { setLogin } from "../../../store/seqr-ops/seqr-ops.slice";
import { useNavigate } from "react-router-dom";
import { setTokens } from "../../../store/keycloak/asset-keycloak.slice";
import { seqrOpsActions } from "../../../store/seqr-ops/seqr-ops.action";

function LoginForm() {
  const dispatch = useDispatch<any>();
  const { register, handleSubmit, reset, formState } = useForm<ILoginCredentials>({
    defaultValues: {
      username: "",
      password: "",
      client_id: "",
      grant_type: "",
    },
  });
  
  const errors: FieldErrors<ILoginCredentials> = formState.errors;
  const navigate = useNavigate();

  const handleLogin = (propertyValues: any) => {
    dispatch(seqrOpsActions.setLoader(true));

    //UX user case(to if close)
    // if (propertyValues.username === "") {
    //   const ITokenDetails: ITokenDetails = {
    //     auth_token: "tempToken",
    //     refresh_token: "tempRefreshToken",
    //     client_id: KEYCLOAK_CLIENTS.ASSET_SUITE,
    //     client_secret: KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE,
    //     username: "ux user",
    //     password: "ux user",
    //   };
    //   dispatch(seqrOpsActions.setLoader(false));
    //   dispatch(setTokens(ITokenDetails));
    //   dispatch(setLogin(true));
    //   navigate("/dashboard");
    // }

    let loginCredentials: ILoginCredentials = {
      username: propertyValues.username,
      password: propertyValues.password,
      grant_type: KEYCLOAK_GRANT_TYPES.PASSWORD,
      client_id: KEYCLOAK_CLIENTS.ASSET_SUITE,
    };
    dispatch(assetKeycloakActions.getLoginToken(loginCredentials))
      .then((ITokenDetails: any) => {
        dispatch(setTokens(ITokenDetails));
        dispatch(seqrOpsActions.setLoader(false));
        navigate("/dashboard");
      })
      .catch((error: any) => {
        dispatch(seqrOpsActions.setLoader(false));
      });
  };

  const errorWrap = (errorMsg: string | undefined) => {
    if (!errorMsg) return null;
    return <p className="error-message-section">{errorMsg}</p>;
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleLogin)}>
        <div className="seqrops-login-form-wrapper">
          <div className="container-fluid">
            <div className="container-fluid seqrops-login-console">
              <div className="row p-2">
                <div className="seqrops-login-form-head-section text-left">
                  <p>Sign in</p>
                </div>
                <div className="username">
                  <div className="userNameLabel">
                    <label className="form-label" htmlFor="username">
                      Username
                    </label>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    id="username"
                    {...register("username", {
                      required: MESSAGES.USERNAME_REQUIRED,
                    })}
                  />
                  <label className="form-label" htmlFor="comments">
                   {errorWrap(errors?.username?.message)}
                  </label>
                </div>
                <div className="p-2">
                  <div className="password">
                    <div className="passwordLabel">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                    </div>
                    <input
                      className="form-control"
                      type="password"
                      id="password"
                      {...register("password", {
                        required: MESSAGES.PASSWORD_REQUIRED,
                      })}
                    />
                    <label className="form-label" htmlFor="comments">
                    {errorWrap(errors?.password?.message)}
                    </label>
                  </div>
                </div>
                <div className="seqrops-login-buttons-section row">
                  {/* <button
                   className="seqrops-btn btn-outline me-2 w-custom-btn"
                   onClick={(e) => {
                    e.preventDefault();
                     handleReset();
                   }}
                 >
                   Cancel
                 </button> */}
                  <button
                    type="submit"
                    className="seqrops-btn btn-fill w-custom-btn w-100 my-3"
                  >
                    Sign in
                  </button>
                </div>
                {/* <div className="seqrops-user-services-section">
                <a href="" onClick={(e)=>{
                  e.preventDefault();
                  alert("functionality in progress");
                   }}>forgot password</a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center"></div>
      </form>
    </>
  );
}

export default LoginForm;
