import { KEYCLOAK_CLIENTS, KEYCLOAK_CLIENT_SECRETS, KEYCLOAK_GRANT_TYPES, MESSAGES } from "../../config/default.config";
import { interceptiorUtils } from "../../utils/interceptor.utils";
import { ILoginCredentials, ILogoutTokenPayload, ITokenDetails, IValidateTokenPayload } from "./asset-keycloak.interface";
import { assetKeycloakService } from "./asset-keycloak.service";
import { TokenServices } from "./token-services";
import { AppThunk } from "..";
import { setTokens } from "./asset-keycloak.slice";
import { toasterUtils } from "../../utils/toaster.utils";

const getLoginToken = (ILoginCredentials : ILoginCredentials) : AppThunk => {
  return async (dispatch, getState) => {

  const params = new URLSearchParams();
  params.append('username', ILoginCredentials.username)
  params.append('password', ILoginCredentials.password)
  params.append('client_id', KEYCLOAK_CLIENTS.ASSET_SUITE);
  params.append('client_secret', KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE)
  params.append('grant_type', KEYCLOAK_GRANT_TYPES.PASSWORD);

  try {
    const response = await assetKeycloakService.getLoginToken(params);
    if (response.status === 200 || response.status === 201 ) {
      var token = response.data.access_token;
      var refresh_token = response.data.refresh_token;

      const ITokenDetails : ITokenDetails = {
        auth_token : token,
        refresh_token : refresh_token,
        client_id : KEYCLOAK_CLIENTS.ASSET_SUITE,
        client_secret : KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE,
        username : ILoginCredentials.username,
        password : ILoginCredentials.password,
      }

      //interceptors
      if(ITokenDetails.auth_token){
        interceptiorUtils.initialize();
      }else {
      }

      let IValidateTokenPayload: IValidateTokenPayload = {
        token : token,
        username : ILoginCredentials.username,
        password : ILoginCredentials.password, 
        client_id : KEYCLOAK_CLIENTS.ASSET_SUITE,
        client_secret : KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE,
        grant_type: KEYCLOAK_GRANT_TYPES.PASSWORD
      }

      //validating token
      if(await validateToken(IValidateTokenPayload, refresh_token, ITokenDetails)){
        return ITokenDetails;
      }else{
        //token not valid
        console.log("token not valid");
      };
    } else {
      return null;
    }
  } catch (err) {
    toasterUtils.showError(MESSAGES.INVALID_LOGIN_CREDENTIALS);
    return null;
  }
}};


export const validateToken = async (IValidateTokenPayload : IValidateTokenPayload, refresh_token: string, ITokenDetails: ITokenDetails) => {

  const params = new URLSearchParams();
  params.append('token', IValidateTokenPayload.token);
  params.append('client_id', KEYCLOAK_CLIENTS.ASSET_SUITE);
  params.append('client_secret', KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE);
  params.append('username', IValidateTokenPayload.username);
  params.append('password', IValidateTokenPayload.password);

  try {
    const response = await assetKeycloakService.validateToken(params);  
    if (response?.data?.active) {
      await TokenServices.processToken(IValidateTokenPayload, ITokenDetails, refresh_token);
     return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
  };


const logoutToken = async (ILogoutTokenPayload: ILogoutTokenPayload, setSuccess : (state: boolean) => void) => {

  const params = new URLSearchParams();
  params.append('refresh_token', ILogoutTokenPayload.refresh_token);
  params.append('client_id', ILogoutTokenPayload.client_id);
  params.append('client_secret', ILogoutTokenPayload.client_secret);

  try {
    const response = await assetKeycloakService.logoutToken(params);
    if (response.status) {
      toasterUtils.showSuccess(MESSAGES.LOGGED_OUT);
      setSuccess(true);
    } else {
      return null;
    }
  } catch (err) {
    return null;
    }
  };


  export const assetKeycloakActions = {
    getLoginToken,
    logoutToken
  };